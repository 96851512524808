.link {
	position: relative;
	white-space: nowrap;
	&::before, &::after {
		content: '';
		position: absolute;
		width: 100%;
		background: currentColor;
		top: 100%;
		left: 0;
		pointer-events: none;
		opacity: 0; }
	&::before {
		height: 10px; }
	&::after {
		height: 1px;
		transition: opacity 0.3s;
		transition-delay: 0s; }
	&:hover {
		&::before {
			opacity: 1;
			animation: lineUp 0.3s ease forwards; }
		&::after {
			opacity: 1;
			transition-delay: 0.3s; } } }

@keyframes lineUp {
	0% {
		transform-origin: 50% 100%;
		transform: scale3d(1, 0.045, 1); }
	50% {
		transform-origin: 50% 100%;
		transform: scale3d(1, 1, 1); }
	51% {
		transform-origin: 50% 0%;
		transform: scale3d(1, 1, 1); }
	100% {
		transform-origin: 50% 0%;
		transform: scale3d(1, 0.045, 1); } }




.js-progress-button {
	position: relative;

	&[disabled] {
		cursor: default; }
	&.state-loading {
		cursor: default; }


	.content {
		&::before, &::after {
			content: "";
			width: 20px;
			height: 20px;
			opacity: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 20px;
			transform: translate(-50%, -50%);
			transition: opacity 0.2s 0.2s; }
		&::before {
			background: url('../img/icons/check-round.svg') no-repeat 50% 50% / contain; }
		&::after {
			background: url('../img/icons/close-round.svg') no-repeat 50% 50% / contain; } }

	.btn-text {
		transition: opacity 0.2s 0.2s; }

	&.state-success {
		.content {
			&::before {
				opacity: 1; } }
		.btn-text {
			opacity: 0; }
		&::after {
			opacity: 0; } }
	&.state-error {
		.content {
			&::after {
				opacity: 1; } }
		.btn-text {
			opacity: 0; }
		&::after {
			opacity: 0; } } }

.notransition {
	transition: none !important; }

.js-progress-button {
	.progress {
		background-color: $accent;
		color: $white;

		.btn-text {
			opacity: 0; } }

	.progress-inner {
		position: absolute;
		left: 0;
		background: $accent; }

	&[data-horizontal] .progress-inner {
		top: 0;
		width: 0;
		height: 100%;
		transition: width 0.3s, opacity 0.3s; } }







@keyframes fadeIn {
	from {
		opacity: 0; }
	to {
		opacity: 1; } }

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100px, 0); }
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0); } }


@include min-screen(1200px) {
	.animated {
		animation-fill-mode: both;
		&.infinite {
			animation-iteration-count: infinite; } }

	.fadeIn, .fadeInUp-1, .fadeInUp-2, .fadeInUp-3, .fadeInUp-4 {
		animation-duration: 800ms; }
	.fadeIn {
		animation-name: fadeIn; }

	.fadeInUp-1, .fadeInUp-2, .fadeInUp-3, .fadeInUp-4 {
		animation-name: fadeInUp; }
	.fadeInUp-1 {
		animation-delay: .2s; }
	.fadeInUp-2 {
		animation-delay: .4s; }
	.fadeInUp-3 {
		animation-delay: .6s; }
	.fadeInUp-4 {
		animation-delay: .8s; } }



.anim-par {
	display: inline-block;
	overflow: hidden;
	span {
		display: inline-block;
		// transform: translate3d(-100%, 100%, 0)
 } }		// transition: all .2s

