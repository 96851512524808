/* -------------------------------------
	Font face
	------------------------------------- */

@include font-face('RFDewi', '../fonts/RFDewi-Regular', 400, 'normal');

@include font-face('RFDewiExtended', '../fonts/RFDewiExtended-Regular', 400, 'normal');
@include font-face('RFDewiExtended', '../fonts/RFDewiExtended-Semibold', 600, 'normal');
@include font-face('RFDewiExtended', '../fonts/RFDewiExtended-Bold', 700, 'normal');
@include font-face('RFDewiExtended', '../fonts/RFDewiExtended-Ultrabold', 800, 'normal');
@include font-face('RFDewiExtended', '../fonts/RFDewiExtended-Black', 900, 'normal');

