/* ----- Forms ----- */

*::placeholder {
	color: rgba($white, .3);
	opacity: 1;
	@include trs(.1s); }

body input:focus:required:invalid, body textarea:focus:required:invalid,
body input:required:valid, body textarea:required:valid {
	color: $white; }


.input {
	display: block;
	width: 100%;
	min-width: 1px;
	height: 50px;
	padding: 0 0 20px;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $white;
	font-size: 24px;
	color: $white;
	letter-spacing: -0.02em;
	@include trs(.1s);
	&:focus {
		outline: none;
		&::placeholder {
			opacity: 0; } }
	@include max-screen($md) {
		font-size: 22px; } }



.input-block {
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-bottom: 20px;
	@include trs(.1s);
	.input {
		&::placeholder {
			opacity: 0; }
		&:focus, &.--placeholder {
			padding-top: 14px;
			padding-bottom: 8px;
			+ .input-placeholder {
				font-size: 12px;
				line-height: 1;
				z-index: 1;
				white-space: nowrap; } } }

	.textarea {
		&:focus, &.--placeholder {
			padding-top: 10px; } } }

.textarea {
	height: 120px;
	resize: none; }

.input-placeholder {
	display: block;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	font-size: 24px;
	line-height: 1;
	letter-spacing: -0.02em;
	color: $white;
	opacity: 0.3;
	transition: all 0.15s ease-in-out;
	@include max-screen($md) {
		font-size: 22px; } }


.privacy {
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.02em;
	color: rgba(167, 165, 165, 0.5); }

.form {
	&__btn {
		margin-top: 40px;
		.submit {
			min-width: 230px; } }
	&__body {
		position: relative; }

	&-message {
		display: flex;
		justify-content: center;
		align-items: center;
		font: 700 40px/1 $title-font;
		padding-right: 17%;
		color: $white;
		background-color: #181616;
		position: absolute;
		z-index: 1;
		top: 1px;
		left: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		opacity: 0;
		transition: all .2s;
		&.is-visible {
			opacity: 1;
			visibility: visible; }
		@include max-screen($md) {
			padding: 0; } } }







