/* ----- header ----- */
.header {
	padding: 28px 0;
	overflow: hidden;
	position: fixed;
	z-index: 9;
	top: 0;
	left: 0;
	width: 100%;
	transition: all .2s;

	&.is-fixed {
		background-color: $white;
		box-shadow: 0 2px 11px rgba($black, .1);
		padding: 20px 0; }

	&__row {
		display: flex;
		align-items: center;

		.logo {
			margin-right: 5%;
			@include max-screen($xl) {
				margin-right: auto; }
			@include max-screen($md) {
				font-size: 4vw; }
			@include max-screen($xsm) {
				font-size: 3vw; } }

		.menu {
			flex: 1 0 0; }

		> .tel {
			@include max-screen($md) {
				font-size: 16px;
				.messanger {
					font-size: 28px; } }
			@include max-screen($xsm) {
				font-size: 14px; } }


		.header-btn {
			@include max-screen($md) {
				margin-left: 4%; } } }



	&-btn {
		@include max-screen($md) {
			font-size: 0;
			&::before {
				margin: 0;
				font-size: 18px; } } } }


.logo {
	font-size: 20px;
	font-weight: 900;
	font-family: $title-font;
	text-transform: uppercase;
	span {
		color: $accent; } }





.menu {
	display: flex;
	align-items: center;
	position: relative;
	padding-right: 150px;

	&-toggle {
		font-size: 10px;
		@include size(2.5em);
		position: relative;
		background: #FFF;
		border-radius: 10px;
		margin-right: 25px;
		background: url('../img/icons/burger.svg') no-repeat 50% 50% / 2em auto;
		@include max-screen($md) {
			margin-right: 4%; } }


	&-list {
		display: flex;
		font-weight: 600;
		margin: 0 auto;
		li {
			margin-right: 2em; }
		// a
		// 	&:hover
		// 		@include min-screen(1200px)
 }		// 			color: $accent

	@include max-screen($xl) {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 99;
		width: 100%;
		max-height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 12vh 8vw 10vh;
		background: rgba($black, .95);
		box-shadow: 0 0 18px rgba(0, 0, 0, 0.12);
		font-size: 7vw;
		font-weight: 800;
		letter-spacing: -0.02em;
		font-family: $title-font;
		color: $white;
		will-change: transform;
		transform: translate3d(-100%, 0, 0);
		opacity: 0;
		visibility: hidden;
		transition: all 0.4s linear;

		&-list {
			display: block;
			padding-bottom: 10vw;
			margin-bottom: 10vw;
			border-bottom: 2px solid rgba(196, 196, 196, 0.3);
			font-weight: inherit;

			li {
				margin-bottom: .4em;
				&:last-child {
					margin-bottom: 0; } } }

		.tel {
			font-size: 1em;
			font-weight: inherit;

			.messanger {
				filter: invert(1);
				font-size: .9em;
				margin-left: .4em; } }

		.lite-link {
			margin-bottom: .4em;
			font-weight: inherit; }

		.button {
			font-size: inherit;
			font-weight: inherit;
			margin-top: 1em;
			height: 2em;
			padding: .5em; }

		.close-btn {
			font-size: 2vw; } } }




.tel {
	display: inline-flex;
	align-items: center;
	font-size: 18px;
	letter-spacing: -0.02em;
	font-weight: 600;

	a {
		@include min-screen(1200px) {
			&:hover {
				color: $accent; } } }

	.messanger {
		font-size: 23px;
		width: 1em;
		height: 1em;
		background: no-repeat 50% 50% / contain;
		margin-left: .5em;
		@include max-screen($xl) {
			font-size: 38px; }
		@include min-screen(1200px) {
			&:hover {
				transform: scale(1.1); } } }

	.telegram {
		background-image: url('../img/icons/telegram.svg'); } }



.lite-link {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	&::after {
		content: '';
		display: block;
		font-size: 11px;
		@include size(1em);
		margin-left: 12px;
		background: url('../img/icons/arrow-blank.svg') no-repeat 50% 50% / contain; }
	@include max-screen($xl) {
		&::after {
			filter: invert(1);
			font-size: .6em;
			position: relative;
			top: .2em; } }
	@include min-screen(1200px) {
		padding: 10px 0 10px 20px;
		position: absolute;
		right: 0;
		&::before {
			content: '';
			display: block;
			position: absolute;
			z-index: -1;
			left: 0;
			top: 0;
			bottom: 0;
			right: -50px;
			background: #E2E4E4; }
		&:hover {
			&::before {
				background-color: darken(#E2E4E4, 8); } } } }







.compensate-for-scrollbar {
	@include min-screen(1200px) {
		.header {
			width: auto;
			right: 17px; } } }

.menu-expanded {
	overflow: hidden;
	.menu {
		transform: translate3d(0, 0, 0);
		opacity: 1;
		visibility: visible; } }

















