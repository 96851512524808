.refs {
	margin: 100px 0;
	@include max-screen($md) {
		margin: 50px 0 80px; }

	&__row {
		margin: 0 -24px;
		@include max-screen($xl) {
			margin: 0 -20px; }
		@include max-screen($lg) {
			margin: 0 -10px;
			&.level-1 {
				display: block;
				margin: 0;
				> .refs__col {
					width: 100%;
					padding: 0; } } } }

	&__col {
		padding: 0 24px;
		margin-bottom: 50px;
		width: 50%;

		.refs__col {
			margin-bottom: 0; }

		&.col-100 {
			width: 100%; }

		@include max-screen($xl) {
			margin-bottom: 40px;
			padding: 0 20px; }

		@include max-screen($lg) {
			padding: 0 10px; }

		@include max-screen($md) {
			margin-bottom: 25px; } } }

.ref {

	&__img {
		display: block;
		overflow: hidden;
		margin-bottom: 3em;
		position: relative;
		font-size: 10px;
		&:last-child {
			margin-bottom: 0; }

		.img--cover {
			width: auto;
			margin: 0 auto; }

		.img-2 {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity 0.3s; }

		canvas {
			display: block;
			height: 100%;
			width: 100%; }

		&.height-1 {
			height: 58.2em;
			&.img-circle {
				width: 58.2em;
				margin-left: auto;
				margin-right: auto; } }

		&.height-2 {
			height: 23.6em; }


		&--simple {
			.img {
				transition: transform .2s linear; }
			@include min-screen(1200px) {
				&:hover {
					.img {
						transform: scale(1.1);
						transition-duration: 2s; } } } }


		@include max-screen($xl) {
			font-size: 8px; }

		@include max-screen($lg) {
			.img--cover {
				width: 100%; }
			[src*='ref-2.jpg'] {
				width: auto; } }
		@include max-screen($md) {
			font-size: 1.25vw; } }

	&-tabs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex; }

	&-tab {
		height: 100%;
		flex: 1 0 0;
		cursor: pointer; }

	&__link {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 2;
		font-size: 10px;
		width: 8em;
		height: 8em;
		border-radius: 50%;
		&::after {
			content: '';
			display: block;
			width: 1.9em;
			height: 1.9em;
			background: url('../img/icons/arrow-blank.svg') no-repeat 50% 50%/contain;
			transition: all .2s; }
		&.white {
			&::after {
				filter: invert(1); } }
		&.blue-bg {
			background-color: #00ADCA; }
		@include max-screen($md) {
			font-size: 7px; }
		@include min-screen(1200px) {
			&:hover {
				&::after {
					transform: translate3d(1em, -.6em, 0); } } } }




	&__name {
		margin-bottom: 25px;
		@include max-screen($md) {
			font-size: 16px;
			margin-bottom: 40px; } } }




.js .js-img-hover img {
	display: none; }


















