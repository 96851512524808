.hero {
	padding: 150px 0 0;
	margin-bottom: 150px;
	background: url('../img/decor-hero.svg') no-repeat 90% 0% / 520px auto;
	overflow: hidden;
	@include max-screen($xxl) {
		padding: 100px 0 0;
		margin-bottom: 100px; }
	@include max-screen($lg) {
		background-size: 50% auto;
		background-position-x: 60vw; }
	@include max-screen($md) {
		margin-bottom: 50px; }

	&__col {
		padding: 0 13vw;
		@include max-screen($lg) {
			padding: 0; } }


	&__title {
		display: flex;
		flex-wrap: wrap;
		max-width: 850px;
		margin: 0 0 10px;
		text-transform: uppercase;
		color: $accent;

		span {
			display: inline-block;
			margin-left: auto; } }




	&-advantages {
		display: flex;
		max-width: 936px;
		margin-top: 30px;
		margin-left: auto;
		font-size: 18px;
		font-weight: 600;
		li {
			// width: 25%
			padding: 0 35px;
			&::before {
				content: '';
				display: block;
				font-size: 10px;
				@include size(1.5em);
				margin: 0 0 1.2em;
				background: url('../img/icons/plus-accent.svg') no-repeat 50% 50% / contain;
				transition: all .2s; } }


		@include max-screen($lg) {
			flex-wrap: wrap;
			margin-left: 0;
			li {
				width: 50%;
				margin-bottom: 30px;
				padding: 0 30px 0 0; } }

		@include max-screen($md) {
			li {
				padding-right: 20px; } }

		@include max-screen($sm) {
			font-size: 16px; }

		@include min-screen(992px) {
			li {
				&:nth-child(2) {
					min-width: 260px; } } }

		@include min-screen(1440px) {
			position: relative;
			right: -5vw; } } }






















