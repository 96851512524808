/* -------------------------------------
	Libs
	------------------------------------- */

/* ----- swiper slider ----- */
@import "../../include/libs/swiper-slider/swiper-bundle.scss";

/* ----- fancybox ----- */
@import "../../include/libs/fancybox-master/jquery.fancybox.scss";

