.services {
	padding: 90px 0;
	background: #181616 url('../img/decor-services.svg') no-repeat 80% 30% / 785px auto;
	color: $white;
	@include max-screen($md) {
		padding: 50px 0 80px;
		overflow: hidden;
		background-size: 485px auto;
		background-position-y: 15%; }

	&-tabs {
		margin-top: 60px;
		.swiper-slide {
			width: auto; }
		.swiper-slide-thumb-active {
			.tab {
				background-color: $white;
				color: #181616; } }
		.tab {
			border: 1px solid rgba(255, 255, 255, 0.2); }

		@include max-screen($md) {
			margin-top: 30px;
			overflow: visible; } }



	&__row {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		padding: 90px 0;
		max-width: 1020px;
		margin: 0 auto;
		@include max-screen($lg) {
			display: block;
			padding-bottom: 0; }
		@include max-screen($md) {
			padding-top: 40px; } }


	&__col {
		flex: 1 0 0;
		max-width: 100%;
		padding-right: 110px;
		@include max-screen($xl) {
			padding-right: 60px; }
		@include max-screen($lg) {
			padding-right: 0; } }



	&__img {
		width: 440px;
		@include max-screen($lg) {
			width: auto;
			margin-bottom: 50px; }
		@include max-screen($md) {
			margin-bottom: 30px; } }

	&__txt {
		font-size: 18px;
		line-height: 1.5;
		@include max-screen($sm) {
			font-size: 16px; } }


	&__btn {
		margin-top: 50px;
		padding-bottom: 1px;
		.button {
			min-width: 230px; }
		@include max-screen($md) {
			margin-top: 40px; } } }


.tab {
	height: 44px;
	padding: 10px 20px;
	border-radius: 30px; }









