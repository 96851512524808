/* ----- footer ----- */
.footer {
	background-color: #181616;
	color: $white;
	padding: 100px 0;
	@include max-screen($md) {
		padding: 70px 0; }

	&__title {
		max-width: 444px;
		margin-bottom: 60px;
		@include max-screen($md) {
			margin-bottom: 40px; } }

	&__row {}


	&__col {
		width: 25%;
		padding-right: 70px;
		@include max-screen($xl) {
			width: 30%; }
		&:nth-child(1) {
			width: 50%;
			@include max-screen($xl) {
				width: 40%; } }
		&:nth-child(3) {
			display: flex;
			flex-direction: column;
			padding-right: 0;
			.ff {
				margin: auto 0 26px; } }

		@include max-screen($lg) {
			width: 100% !important;
			padding-right: 0;
			margin-bottom: 50px;
			&:last-child {
				margin-bottom: 0; }
			&:nth-child(2), &:nth-child(3) {
				display: flex;
				flex-wrap: wrap;
				.footer-data {
					width: 50%; } }
			&:nth-child(3) {
				flex-direction: row;
				align-items: flex-end;
				.footer-data {
					margin: 0; }
				.ff {
					margin: 0; } } }

		@include max-screen($sm) {
			&:nth-child(2) {
				display: block;
				.footer-data {
					width: 100%; } }
			&:nth-child(3) {
				.footer-data {
					width: auto;
					flex: 1 0 0;
					max-width: 100%;
					padding-right: 20px; } } } }


	.form {
		max-width: 500px; }

	&-data {
		margin-bottom: 50px;
		@include max-screen($md) {
			margin-bottom: 25px; }
		&:last-child {
			margin-bottom: 0; } }

	&-txt {
		margin-bottom: 4px;
		color: rgba(255, 255, 255, 0.3);
		line-height: 1.3; }

	&-accent {
		font-size: 24px;
		letter-spacing: -0.02em; }

	a.footer-accent {
		&:hover {
			color: $accent; } } }


.ff {
	font-weight: 900;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	color: #DE0D4C; }































