.nums {
	position: relative;
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		position: absolute;
		z-index: 0;
		left: 0;
		bottom: 0;
		background: $black; }

	.container {
		@include max-screen($lg) {
			padding: 0; } }

	&__row {
		display: flex;
		@include max-screen($lg) {
			flex-wrap: wrap; } }

	&__col {
		width: 25%;

		@include min-screen(998px) {
			&:first-child {
				.num {
					padding-left: 0; } }
			&:last-child {
				.num {
					padding-right: 0;
					border-right: none; } } }

		@include max-screen($lg) {
			width: 50%; } } }


.num {
	height: 100%;
	padding: 70px 5vw;
	border-right: 1px solid $black;
	border-bottom: 1px solid $black;

	&__val {
		font-size: 60px;
		line-height: 1;
		color: $accent;
		font-weight: 800;
		margin-bottom: .2em; }

	&__txt {
		font-size: 36px;
		line-height: 1; }

	@include max-screen($xl) {
		&__txt {
			font-size: 28px; } }

	@include max-screen($md) {
		padding: 40px 20px;

		&__val {
			font-size: 50px; }
		&__txt {
			font-size: 24px; } } }








