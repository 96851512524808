/* -------------------------------------
	Common styles
	------------------------------------- */
.main-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	.page {
		flex: 1; } }
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.main-wrapper {
		display: block; } }

.wrapper {
	margin-top: 82px; }


.container {
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto; }

// @media (min-width: 1200px)
// 	.container
// 		max-width: 1200px

@media (min-width: 1500px) {
	.container {
		max-width: 1540px; } }


.flex, .flex-center {
	display: flex;
	flex-wrap: wrap; }

.flex-center {
	align-items: center;
	justify-content: center; }

.img {
	display: block;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	&--cover, &--contain {
		width: 100%;
		height: 100%; }
	&--cover {
		object-fit: cover; }
	&--contain {
		object-fit: contain; } }
.svg {
	display: block;
	max-width: 100%;
	max-height: 100%; }

.nowrap {
	white-space: nowrap; }

.text-center {
	text-align: center; }

.list {
	margin: 0;
	padding: 0;
	list-style: none; }


.hide-xl {
	@include max-screen($xl) {
		display: none !important; } }

.show-xl {
	@include min-screen(1200px) {
		display: none !important; } }


/* ---------- Titles & text ---------- */
h1, .title-1 {
	font-size: 100px;
	line-height: .9;
	@include max-screen($xl) {
		font-size: 80px; }
	@include max-screen($md) {
		font-size: 68px; }
	@include max-screen($sm) {
		font-size: 48px; } }

h2, .title-2 {
	font-size: 60px;
	line-height: 0.9;
	@include max-screen($xl) {
		font-size: 50px; }
	@include max-screen($md) {
		font-size: 42px; }
	@include max-screen($sm) {
		font-size: 32px; } }

h3, .title-3 {
	font-size: 40px;
	line-height: 1.05;
	@include max-screen($md) {
		font-size: 32px; } }

.fw-800 {
	font-weight: 800; }

/* ----- Кнопки ----- */
.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 52px;
	font-size: 16px;
	line-height: 1;
	position: relative;
	z-index: 1;
	cursor: pointer;
	overflow: hidden;
	@include trs(.1s);

	&-accent {

		&-bg {
			background-color: $accent;
			color: $white; }


		&-bd {
			border: 1px solid $accent;
			color: $accent;
			@include min-screen(1200px) {
				&:hover {
					background-color: $accent;
					color: $white; }
				&.button-arrow {
					&:hover {
						&::after {
							filter: brightness(100); } } } } } }

	&-black {

		&-bg {
			background: #181616;
			color: $white;
			&:hover {
				@include min-screen(1200px) {
					background-color: $white;
					color: #181616; } } } }

	&-arrow {
		&::after {
			content: '';
			display: block;
			font-size: 10px;
			@include size(1.9em);
			margin-left: 1em;
			margin-top: .1em;
			background: url('../img/icons/arrow-right-accent.svg') no-repeat 50% 50% / contain;
			transition: all .2s; } } }


.btn-plus {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	&::before {
		content: '';
		display: block;
		font-size: 10px;
		@include size(1.5em);
		margin-right: 10px;
		background: url('../img/icons/plus-accent.svg') no-repeat 50% 50% / contain;
		transition: all .2s; }
	&:hover {
		&::before {
			transform: rotate(90deg); } } }


.close-btn {
	font-size: 10px;
	@include size(4em);
	background: url('../img/icons/cross-white.svg') no-repeat 50% 50% / contain;
	position: absolute;
	top: 20px;
	right: 20px; }




.js-tab-content, .js-accordion-content {
	display: none; }



.text-stroke {
	color: $text-color;
	@supports (text-stroke: 2px $text-color) {
		color: transparent;
		-webkit-text-stroke: 2px $text-color;
		text-stroke: 2px $text-color; }
	@supports (-webkit-text-stroke: 2px $text-color) {
		color: transparent;
		-webkit-text-stroke: 2px $text-color;
		text-stroke: 2px $text-color; } }



.home .header .logo {
	cursor: pointer; }


















// @include max-screen($xxl)

// @include max-screen($xl)

// @include max-screen($lg)

// @include max-screen($md)

// @include min-screen(1200px)
