$default-font: 'RFDewi', sans-serif;
$title-font: 'RFDewiExtended', sans-serif;

$white: #fff;
$black: #000;
$accent: #DE0D4C;

$text-color: $black;


// Screen Sizes
$xxl: 1439px;
$xl: 1199px;
$lg: 991px;
$md: 767px;
$sm: 575px;
$xsm: 374px;
