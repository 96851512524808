.partners {
	padding: 120px 0 20px;
	@include max-screen($md) {
		padding: 70px 0 1px; }

	&__title {
		font-weight: 400;
		max-width: 850px;
		margin-bottom: 100px;
		@include max-screen($lg) {
			margin-bottom: 50px; } }

	&__row {
		align-items: center; }

	&__col {
		width: 20%;
		margin-bottom: 100px;
		@include max-screen($lg) {
			width: 33.33333%;
			margin-bottom: 70px; }

		.img {
			margin: 0 auto;
			object-fit: contain;
			max-height: 100px;
			max-width: 130px;
			transition: all .2s;
			filter: grayscale(1);
			&:hover {
				filter: grayscale(0); }
			@include max-screen($lg) {
				max-width: 66%; } } } }




















