@mixin font-face($font-name, $path, $weight, $style) {
	@font-face {
		font-family: quote($font-name);
		src: url("#{$path}.woff2") format("woff2"), url("#{$path}.woff") format("woff");
		font-weight: #{$weight};
		font-style: #{$style};
		font-display: swap; } }

@mixin max-screen($size) {
	@media screen and (max-width : $size) {
		@content; } }

@mixin min-screen($size) {
	@media screen and (min-width : $size) {
		@content; } }

@mixin trs($duration) {
	transition: all $duration linear; }

@mixin size($size) {
	width: $size;
	min-width: $size;
	height: $size; }
